const React = require('react');
const { useEffect } = require('react');
const PropTypes = require('prop-types');
const Head = require('nordic/head');
const { Page } = require('nordic/page');
const Script = require('nordic/script');
const Style = require('nordic/style');
const serialize = require('serialize-javascript');
const MeliGA = require('nordic/analytics/meli-ga');
const MelidataTrack = require('nordic/melidata/melidata-track');
const TrackingService = require('@services/Tracking/TrackingService');
const MeliGaResource = require('@services/Tracking/resources/MeliGA');
const { getTrackingPath } = require('@services/Tracking/utils');
const Hotjar = require('../../components/Hotjar');

const { componentListHub } = require('@utils/componentList');
const Reader = require('../../components/Reader');
const { LandingProvider } = require('@splinter/context');

const View = ({
  dataLanding,
  siteId,
  lowEnd,
  deviceType,
  deviceOs,
  hasCtaFixed,
  webView,
  isLegacyBrowser,
  platformId,
  nativeAppName,
  landingType,
  googleTagManager,
  analytics,
  startTime,
  endTime,
  xExperimentName,
  xExperimentVariant,
  hotjar,
  xDebugHotjar,
  d2id,
  dealPrintId,
  serverTime,
  inlineCss,
  dpr,
  platformType,
  locale,
  isIndexable,
}) => {
  const preloadedState = {
    dataLanding,
    siteId,
    lowEnd,
    deviceType,
    deviceOs,
    hasCtaFixed,
    webView,
    platformId,
    nativeAppName,
    googleTagManager,
    analytics,
    startTime,
    endTime,
    xExperimentName,
    xExperimentVariant,
    hotjar,
    xDebugHotjar,
    d2id,
    dealPrintId,
    serverTime,
    platformType,
    dpr,
    locale,
  };

  /* istanbul ignore next */
  useEffect(() => {
    TrackingService.sendAllTracks({ dataLanding, dealPrintId, landingType, skeleton: null, platformId, siteId });
  }, [dataLanding, dealPrintId, landingType, platformId, siteId]);
  const path = MeliGaResource.generateDynamicPath(dataLanding, landingType);
  const trackingPath = getTrackingPath(path);
  const { meliGA, melidata } = trackingPath;
  const { theme, seo, name, nativeTitle } = dataLanding;
  const defaultTitle = webView ? nativeTitle : seo?.meta?.title;
  const title = defaultTitle || name;
  const description = seo?.meta?.description;
  const canonical = seo?.canonical;
  const hasExtraMargin = hasCtaFixed && webView ? 'extra-margin-cta' : '';
  const customClassName = `main ${theme} ${hasExtraMargin}`.trim();
  const experiment = { [xExperimentName]: xExperimentVariant };
  const dimensions = { melidataExperiments: JSON.stringify([experiment]) };

  return (
    <Page name="ads" className={customClassName} state={preloadedState}>
      <MeliGA
        siteId={siteId}
        platform={platformId}
        section={meliGA.section.deals}
        page={meliGA.page.deals}
        dimensions={xExperimentName ? dimensions : undefined}
        secure
        isDeferred
      />
      <MelidataTrack
        path={melidata.deals}
        event_data={{ key: analytics, startTime, endTime }}
        experiments={xExperimentName ? experiment : undefined}
        isDeferred
      />
      <Hotjar {...(dataLanding.hotjar || hotjar)} debug={xDebugHotjar} d2id={d2id} />
      <Head>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {isIndexable === false && <meta name="robots" content="noindex" />}
        {canonical && <link rel="canonical" href={canonical} data-head-react="true" />}
        {(webView || dataLanding.clientSide) && (
          <Script src="@frontend-performance/image-lazy-loading/src.js" on="now" inline />
        )}
      </Head>

      <LandingProvider
        landingData={dataLanding}
        dpr={dpr}
        siteId={siteId}
        lowEnd={lowEnd}
        deviceType={deviceType}
        osName={deviceOs}
        googleTagManager={googleTagManager}
        serverTime={serverTime}
        platformType={platformType}
        webView={webView}
        title={title}
        nativeAppName={nativeAppName}
      >
        <Reader landingData={dataLanding} webView={webView} deviceType={deviceType} componentList={componentListHub} />
      </LandingProvider>
      {
        // This workaround enable inlineCss on webViews
        Array.isArray(inlineCss) && inlineCss.length && (
          <Style renderChildrenInPlace={false}>{inlineCss.join(' ')}</Style>
        )
      }

      <Style href="ads.css" critical />
      <Script>{`window.__PRELOADED_STATE__ = ${serialize(preloadedState, { isJSON: true })};`}</Script>
      {isLegacyBrowser && <Script src="polyfills.js" />}
      <noscript></noscript>
    </Page>
  );
};

View.propTypes = {
  analytics: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  landingType: PropTypes.string.isRequired,
  deviceType: PropTypes.string,
  deviceOs: PropTypes.string,
  dataLanding: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nativeTitle: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
    site: PropTypes.shape({
      analytics: PropTypes.string.isRequired,
      seo_text: PropTypes.string,
    }).isRequired,
    seo: PropTypes.shape({
      meta: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      canonical: PropTypes.string,
    }).isRequired,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        component_name: PropTypes.string,
      }),
    ).isRequired,
    clientSide: PropTypes.bool,
    hotjar: PropTypes.shape({
      id: PropTypes.number.isRequired,
      traffic: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
  lowEnd: PropTypes.bool,
  hasCtaFixed: PropTypes.bool.isRequired,
  webView: PropTypes.bool.isRequired,
  googleTagManager: PropTypes.string.isRequired,
  xExperimentName: PropTypes.string,
  xExperimentVariant: PropTypes.string,
  isLegacyBrowser: PropTypes.bool.isRequired,
  platformId: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  hotjar: PropTypes.shape({}).isRequired,
  xDebugHotjar: PropTypes.bool.isRequired,
  d2id: PropTypes.string.isRequired,
  dealPrintId: PropTypes.string.isRequired,
  serverTime: PropTypes.number.isRequired,
  dpr: PropTypes.string,
  platformType: PropTypes.string,
};

View.defaultProps = {
  deviceType: null,
  deviceOs: '',
  lowEnd: false,
  xExperimentName: null,
  xExperimentVariant: null,
  startTime: null,
  endTime: null,
  dpr: null,
  platformType: null,
};

module.exports = View;
